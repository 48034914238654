/*!
 * formBox - basic form loader build on fancybox for www.signupto.com forms
 * version: 2.0.3 (15 May 2016)
 * @requires jQuery v1.6 or later
 * Waits 'theTimeout' milliseconds after page load, then displays formURL in a lightbox. Uses a cookie to only show once in 'daysHide' days
 * Checks to see if we're on a mobile device - if so, will not fire
 */

(function($) {

/* Are we on a mobile device? */
 var device = {
            is_android: function() {
                return navigator.userAgent.match(/Android/i);
            },
            is_blackberry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            is_iphone: function() {
                return navigator.userAgent.match(/iPhone/i);
            },
            is_ipad: function() {
                return navigator.userAgent.match(/iPad/i);
            },
            is_ipod: function() {
                return navigator.userAgent.match(/iPod/i);
            },
            is_ios: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            is_windows_phone: function() {
                return navigator.userAgent.match(/IEMobile/i);
            },
            is_mobile: function() {
                return (device.is_android() || device.is_blackberry() || device.is_ios() || device.is_windows_phone() );
            }
    };
    
    if( device.is_mobile() )
        {
            // The User Is Using A Mobile Phone - do nothing
           return;
        } else {
					/* Set the form URL */
					var formURL = 'https://forms.sign-up.to/15890/1576'  /* URL of the form to load */
					var daysHide = 21;  /* Days before re-showing the form */
					var theTimeout = 15000; /* How long to wait in milliseconds before showing the form */

					function openBox() {
						$("#hidden_link").fancybox({ 'width': '450', 'height': '420' }).trigger('click');
					}

					function createCookie(name, value, daysHide) {
						if (daysHide) {
							var date = new Date();
							date.setTime(date.getTime() + (daysHide * 24 * 60 * 60 * 1000));
							var expires = "; expires=" + date.toGMTString();
						} else
							var expires = "";
						document.cookie = name + "=" + value + expires + "; path=/";
					}

					function readCookie(name) {
						var nameEQ = name + "=";
						var ca = document.cookie.split(';');
						for (var i = 0; i < ca.length; i++) {
							var c = ca[i];
							while (c.charAt(0) == ' ')
							c = c.substring(1, c.length);
							if (c.indexOf(nameEQ) == 0)
								return c.substring(nameEQ.length, c.length);
						}
						return null;
					}

					function doCookie() {
						if (readCookie('newsForm')) {
							return;
						} else {
							createCookie('newsForm', 'true', daysHide);
							setTimeout(openBox, theTimeout);
						}
					}

					$(document).ready(function() {
						$("body").append('<a id="hidden_link" class="fancybox fancybox.iframe" style="display:none;" href="' + formURL + '"></a>');
						$('.fancybox').fancybox();
						doCookie();
					});        
        }
})(jQuery);